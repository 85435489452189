import { createMuiTheme } from '@material-ui/core'

export const theme = createMuiTheme({
    overrides: {
      MuiCssBaseline: {
        '@global': {
          html: {
            fontFamily: ['Noto Sans KR', 'Roboto', 'sans-serif'].join(','),
          },
          body: {
            fontSize: 16,
            lineHeight: 1.5,
            fontWeight: 400,
            boxSizing: 'border-box',
          },
          'html, body, div, span, iframe, h1, h2, h3, h4, h5, h6, p, a, img, dl, dt, dd, ol, ul, li, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, footer, header ': {
            margin: 0,
            padding: 0,
            border: 0,
            fontSize: '100%',
            verticalAlign: 'baseline',
          },
          'ol, ul': {
            listStyle: 'none',
          },
          table: {
            borderCollapse: 'collapse',
            borderSpacing: 0,
          },
          a: {
            display: 'block',
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'none',
            },
          },
          img: {
            maxWidth: '100%',
          },
        },
      },
    },
  });
  
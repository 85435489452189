import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  header : {
      
  },
  logo: {
    margin: 'auto',
    fontSize: '8rem',
    color: '#000',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontFamily: ['Bungee Inline', 'cursive'].join(','),
  },
  nav: {
      display: 'flex',
  },
  item: {
      position: 'fixed',
      zIndex: 1,
      padding: 30,
      fontSize: '1.5rem',
      color: '#fbaf3f',
      textDecoration: 'underline',
      textTransform: 'uppercase'
  },
  item1: {
    top: 0,
    left: 0,
  },
  item2: {
    top: 0,
    right: 0,
  },
  item3: {
    left: 0,
    bottom: 0,
  },
  item4: {
    bottom: 0,
    right: 0,
  }
});

const Header = () => {
  const classes = useStyles();
  return (
      <header className={classes.header}>
        <a className={classes.logo} href="">hyung</a>
          <nav className={classes.nav}>
              <a className={clsx(classes.item, classes.item1)} href="">home</a>
              <a className={clsx(classes.item, classes.item2)} href="">about</a>
              <a className={clsx(classes.item, classes.item3)} href="">potfolio</a>
              <a className={clsx(classes.item, classes.item4)} href="">contact</a>
          </nav>
      </header>
  )
}

export default Header